function hash(inp) {
    var h = 0, i, chr;
    if (inp.length === 0) return h;
    for (i = 0; i < inp.length; i++) {
      chr   = inp.charCodeAt(i);
      h  = ((h << 5) - h) + chr;
      h |= 0;
    }
    return h;
  };

function id(){
    const cookieParts = document.cookie.split(';').map((s)=>s.split('='));
    const c = {};
    for(var a = 0; a < cookieParts.length; a ++){
        c[cookieParts[a][0]] = cookieParts[a][1];
    }

    if('userId' in c){
        return c.userId;
    } else {
        const newId = hash(navigator.userAgent + Date.now());
        document.cookie = 'userId=' + newId;
        return newId;
    }
}

function rc(){
    let client = {
        'url': "https://RephrasorService.pythonanywhere.com/",
        'userId': id()
    };

    client.requestRephrase = function(onSuccess, onError){
        fetch(this.url + 'requestRephrase', {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
                'userId': this.userId
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then((s)=> s.json()
            .then(function(s){
                onSuccess(s)
            }, (e)=>onError(e), (e)=>onError(e)),
            (e)=>onError(e)
        );
        
    };

    client.submitPhrase = function(text, goal, rephraseId, onSuccess, onError) {
        console.log("Submitting phrase. text, goal, rephraseId", text, goal, rephraseId)
        fetch(this.url + 'submitPhrase', {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
                'userId': this.userId,
                'text': text,
                'goal': goal,
                'rephraseId': rephraseId
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then((s)=> s.json()
            .then((j)=> onSuccess(j), (e)=> onError(e)),
        (e) => onError(e)
        );
    };

    client.pickAVote = function(onSuccess, onError){
        fetch(this.url + 'pickAVote', {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
                'userId': this.userId
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then((s)=> s.json()
            .then(function(s){
                onSuccess(s)
            }, (e)=>onError(e), (e)=>onError(e)),
            (e)=>onError(e)
        );
        
    };

    client.vote = function(winnerId, loserId, onSuccess, onError){
        fetch(this.url + 'vote', {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
                'userId': this.userId,
                'winner': winnerId,
                'loser': loserId
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then((s)=> s.json()
            .then(function(s){
                onSuccess(s)
            }, (e)=>onError(e), (e)=>onError(e)),
            (e)=>onError(e)
        );
        
    };


    client.submitRephrase = function(text, goal, onSuccess, onError){
        fetch(this.url + 'submitRephrase', {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
                'userId': this.userId,
                'text': text,
                'goal': goal
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then((s)=> s.json()
            .then(function(s){
                onSuccess(s)
            }, (e)=>onError(e), (e)=>onError(e)),
            (e)=>onError(e)
        );
        
    };


    client.userRephraseHistory = function(startIndex, onSuccess, onError){
        fetch(this.url + 'userRephraseHistory', {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
                'userId': this.userId,
                'startIndex': startIndex,
                'pageSize': 10
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then((s)=> s.json()
            .then(function(s){
                onSuccess(s)
            }, (e)=>onError(e), (e)=>onError(e)),
            (e)=>onError(e)
        );
        
    };

    client.userPhraseHistory = function(startIndex, onSuccess, onError){
        fetch(this.url + 'userPhraseHistory', {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
                'userId': this.userId,
                'startIndex': startIndex,
                'pageSize': 10
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then((s)=> s.json()
            .then(function(s){
                onSuccess(s)
            }, (e)=>onError(e), (e)=>onError(e)),
            (e)=>onError(e)
        );
        
    };


    client.name = function(onSuccess, onError){
        fetch(this.url + 'name', {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
                'key': this.userId
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then((s)=> s.json()
            .then(function(s){
                onSuccess(s)
            }, (e)=>onError(e), (e)=>onError(e)),
            (e)=>onError(e)
        );
        
    };

    client.metrics = function(onSuccess, onError){
        fetch(this.url + 'metrics', {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
                'userId': this.userId
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then((s)=> s.json()
            .then(function(s){
                onSuccess(s)
            }, (e)=>onError(e), (e)=>onError(e)),
            (e)=>onError(e)
        );
        
    };

    client.computeMetrics = function(onSuccess, onError){
        fetch(this.url + 'computeMetrics', {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
                'userId': this.userId
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then((s)=> s.json()
            .then(function(s){
                onSuccess(s)
            }, (e)=>onError(e), (e)=>onError(e)),
            (e)=>onError(e)
        );
        
    };


    return client;
}

export default rc();