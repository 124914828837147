import React from 'react';
import logo from './logo.svg';
import './App.css';
import rc from './RephrasorServiceClient'

const ACTIVE_CONTENT = {
  'home': 0,
  'rephrase': 1,
  'rate': 2,
  'review': 3,
  'request': 4,
  'reflect': 5
}

const GOALS = {
  'clear': "Clear",
  'clever': "Clever",
  'correct': "Correct",
  'creative': "Creative"
}

function goal_to_name(g){
  return GOALS[Object.keys(GOALS)[g]];
}

class BottomButtonPanel extends React.Component {

  render() {
    return (
      <div className="bottomButtonPanel">
        <button className="bottomButton" onClick={(e)=>this.props.changeState(ACTIVE_CONTENT.home)}>return</button>
        {this.props.actionButton && (<button className="bottomButton" onClick={this.props.actionButton.action}>{this.props.actionButton.text}</button>)}
      </div>
    );
  }
}

class ContentReflect extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      'name': "",
      'key': rc.userId,
      'metricsObject': {
        "clever": "--",
        "clear": "--",
        "creative": "--",
        "correct": "--",
        "rephraseRequests": "--",
        "rephraseSubmissions": "--",
        "rephraseVotes": "--",
        "voteVictories": "--"
      }
    }

    this.populateName = this.populateName.bind(this);
    
    this.lastNamePopulate = 0;
    this.pendingNamePopulate = false;
    rc.computeMetrics((s)=>console.log("Computed metrics."), (e)=>console.log(e));
  }

  componentDidMount(){
    this.populateName();

    /*
    {
      "clearScore":0,
      "cleverScore":1065,
      "correctScore":0,
      "creativeScore":969,
      "lastUpdated":"Sun, 19 Jul 2020 06:43:07 GMT",
      "phrasesSubmitted":38,
      "rephrasesRequested":17,
      "votes":104,
      "votesWon":52
    }
    */
    rc.metrics((s)=>{
      this.setState(
        {'metricsObject': {
        'clever': s.cleverScore,
        'clear': s.clearScore,
        'correct': s.correctScore,
        'creative': s.creativeScore,
        'rephraseRequests': s.rephrasesRequested,
        "rephraseSubmissions": s.phrasesSubmitted,
        "rephraseVotes": s.votes,
        "voteVictories": s.votesWon
      }});
    }, (e)=> console.log(e));
  }

  populateName(){
    if(Date.now() - this.lastNamePopulate < 1500) {
      if(!this.pendingNamePopulate) {
        this.pendingNamePopulate = true;
        setTimeout(()=> {
          this.pendingNamePopulate = false;
          this.populateName();
        }, 1700);
      }
      return;
    }
    this.lastNamePopulate = Date.now();

    rc.name((s)=>{
      this.setState({'name': s.name}, (s) => rc.userId = this.state.key)
    }, (e) => console.log(e));
  }

  render() {
    return (
      <div className="contentReflect">
        <div className="reflectIdentityHeaders">
          <div className="reflectHeader">Name</div>
          <div className="reflectHeader">Key</div>
        </div>
        <div className="reflectIdentityPanel">
          <textarea className="reflectIdentityText displayOnly" readOnly defaultValue={this.state.name}/>
          <textarea className="reflectIdentityText" value={this.state.key} onChange={(e)=>{
            this.setState({key: e.target.value});
            this.populateName();
          }}/>
        </div>
        <div className="reflectMetricsPanel">
          <h1>Your stats</h1>
          <div className="reflectScore">{this.state.metricsObject.rephraseRequests} rephrase requests</div>
          <div className="reflectScore">{this.state.metricsObject.rephraseSubmissions} rephrases submitted</div>
          <div className="reflectScore">Won {this.state.metricsObject.voteVictories} of {this.state.metricsObject.rephraseVotes}. {this.state.metricsObject.voteVictories > 0 && parseInt((this.state.metricsObject.voteVictories / this.state.metricsObject.rephraseVotes) * 100) + "%"}</div>
          <div className="reflectScore">Creative score: {this.state.metricsObject.clever}</div>
          <div className="reflectScore">Clear score: {this.state.metricsObject.clear}</div>
          <div className="reflectScore">Creative score: {this.state.metricsObject.creative}</div>
          <div className="reflectScore">Correct score: {this.state.metricsObject.correct}</div>
        </div>
        <BottomButtonPanel changeState={this.props.changeState}/>
      </div>
    );
  }
}

class PhraseDisplay extends React.Component {
  render(){
    return (
      <div className="reviewTextContainer">
        <div className="phraseDisplayPreface">
          <div className="reviewGoalTitle">{goal_to_name(this.props.phrase.goal)}</div>
          <div className="phraseDisplayScore">{this.props.phrase.score}</div>
        </div>
        
        <textarea readOnly className="displayTextArea reviewOriginalPhrase" defaultValue={this.props.phrase.text} />
        <textarea readOnly className="displayTextArea reviewTopPhrase" defaultValue={this.props.phrase.best} />
      </div>
    )
  }
}

class ContentReview extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      'myRephrases': [],
      'myPhrases': [],
      'rephraseDone': false,
      'phraseDone': false
    }

    this.rephraseHistoryPage = this.rephraseHistoryPage.bind(this);
    this.needNewPage = this.needNewPage.bind(this);

    this.lastRephraseHistory = 0;
    this.lastPhraseHistory = 0;
  }

  phraseHistoryPage() {
    if(Date.now() - this.lastPhraseHistory < 800 || this.state.phraseDone){
      return;
    }

    this.lastPhraseHistory = Date.now();

    rc.userPhraseHistory(
      this.state.myPhrases.length,
      (s) => {
        this.setState({'myPhrases': this.state.myPhrases.concat(s.results), 'phraseDone': s.results.length < 1});
      },
      (e) => console.log(e)
    )
  }

  rephraseHistoryPage() {
    if(Date.now() - this.lastRephraseHistory < 800 || this.state.rephraseDone){
      return;
    }

    this.lastRephraseHistory = Date.now();

    rc.userRephraseHistory(
      this.state.myRephrases.length,
      (s) => {
        console.log(s.results);
        this.setState({'myRephrases': this.state.myRephrases.concat(s.results), 'rephraseDone': s.results.length < 1});
      },
      (e) => console.log(e)
    );
  }

  needNewPage(element, f) {
    let percent = ((element.scrollTop) / element.scrollTopMax) * 100;
    if (percent > 80){
      f();
    }
  }

  componentDidMount() {
    this.rephraseHistoryPage();
    this.phraseHistoryPage();
  }

  render(){
    return (
      <div className="contentReview">
        <div className="reviewCurrentPanel">
          <div className="reviewHeader">
            <h1>My requests</h1>
          </div>
          <div className="reviewPanel">
            <div className="phraseListContainer" onScroll={(e) => this.needNewPage(e.target, this.rephraseHistoryPage)}>
              {this.state.myRephrases.map((phrase) => (<PhraseDisplay phrase={phrase} key={phrase.id}/>))}
            </div>
          </div>
          <div className="reviewHeader">
            <h1>My submissions</h1>
          </div>
          <div className="reviewPanel">
            <div className="phraseListContainer" onScroll={(e) => this.needNewPage(e.target, this.phraseHistoryPage)}>
              {this.state.myPhrases.map((phrase) => (<PhraseDisplay phrase={phrase} key={phrase.id}/>))}
            </div>
          </div>
          <BottomButtonPanel changeState={this.props.changeState}/>
        </div>
      </div>
    );
  }
}

class ContentRate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      'one': {
        'text': "Loading..."
      },
      'two': {
        'text': "Loading..."
      },
      'original': "Loading...",
      'goal': "Style",
      'loading': true
    };

    this.vote = this.vote.bind(this);
    this.newVote = this.newVote.bind(this);
  }

  componentDidMount(){
    this.newVote();
  }

  newVote(){
    rc.pickAVote(
      (s) => {
        console.log("Pick a vote success.");
        this.setState({
          'one': {"text": s.oneText, "id": s.oneId},
          'two': {"text": s.twoText, "id": s.twoId},
          'goal': GOALS[Object.keys(GOALS)[s.goal]],
          'original': s.original,
          'loading': false
        });
      },
      (e) => console.log(e)
    )
  }

  vote(winner, loser){
    rc.vote(winner, 
      loser, 
      (s)=>{
        this.setState({'loading': true, 'one': {'text': "Loading..."}, 'two': {'text': "Loading..."}});
        this.newVote();
      },
      (e)=> console.log(e)
    );
  }

  render(){
    return (
      <div className="contentRateContainer">
        <div className="homeMargin" />
        <div className="contentRate">
        <h1 className="rephraseTitle">Rephrase for '{this.state.goal}'</h1>
        <div className="rateRowOne">
            <div className="rateTextContainer">
              <textarea readOnly className="displayTextArea displayOnly" value={this.state.original} />
            </div>
        </div>
        <div className="rateRowOne">
            <div className="rateTextContainer">
              <textarea readOnly className="displayTextArea" value={this.state.one.text} />
            </div>
            <div className="voteButtonContainer">
              <button className="voteButton" onClick={()=>this.vote(this.state.one.id, this.state.two.id)}>Vote</button>
            </div>
        </div>
        <div className="rateRowTwo">
            <div className="rateTextContainer">
              <textarea readOnly className="displayTextArea" value={this.state.two.text} />
            </div>
            <div className="voteButtonContainer">
              <button className="voteButton" onClick={()=>this.vote(this.state.two.id, this.state.one.id)}>Vote</button>
            </div>
            
        </div>
        <BottomButtonPanel changeState={this.props.changeState}/>
      </div>
        <div className="homeMargin" />
      </div>
    );
  }
}


class ContentRephrase extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      'text': `Loading...`,
      'goal': "Creative",
      'count': 0,
      'rephrase': "",
      'requestInProgress': true
    }

    this.getNewRephrase = this.getNewRephrase.bind(this);
    this.submitPhrase = this.submitPhrase.bind(this);

  }

  componentDidMount() {
    this.getNewRephrase();
  }

  submitPhrase(){
    if(this.state.rephrase.length < 10) {
      this.getNewRephrase();
      return;
    }
    this.setState({
      'requestInProgress': true,
      'text': "Loading..."
    }, ()=>{
      rc.submitPhrase(
        this.state.rephrase, 
        this.state.goal, 
        this.state.rephraseId,
        (s) => this.getNewRephrase(),
        (e) => console.log(e)
      );
    });
  }

  getNewRephrase() {
    this.setState({'requestInProgress': true}, rc.requestRephrase((r)=>{
      console.log(r)
      this.setState({
        'requestInProgress': false,
        'text': r.text,
        'goal': GOALS[Object.keys(GOALS)[r.goal]],
        'count': this.state.count + 1,
        'rephrase': "",
        "rephraseId": r.id
      });
      console.log(this.state.text);
    }, (e)=>{console.log(e)}));
    
  }

  render() {
    return (
      <div className="contentRephrase">
        <div className="homeMargin" />
        <div className="rephraseContainer">
          <h1 className="rephraseTitle">{!this.state.requestInProgress && `Rephrase for ${this.state.goal}`}{this.state.requestInProgress && "Loading..."}</h1>
          <div className="displayTextPanel">
            <textarea readOnly className="displayTextArea" value={this.state.text} />
          </div>
          
          <div className="textEntryPanel">
            <textarea className="enterTextArea" placeholder="Your rephrasal here, please..." value={this.state.rephrase} onChange={(e) => this.setState({'rephrase': e.target.value})} />
          </div>
          <BottomButtonPanel changeState={this.props.changeState} actionButton={{'action': (e)=> this.submitPhrase(), 'text': "rephrase"}}/>
        </div>
        <div className="homeMargin" />
      </div>
    )
  }
}

class ContentRequest extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      'goal': GOALS.clever,
      'phrase': "",
      'requestInProgress': false
    }

    this.requestRephrase = this.requestRephrase.bind(this);
  }

  requestRephrase(){
    if(this.state.phrase.length < 10 || this.state.phrase == "Thanks!"){
      return;
    }

    rc.submitRephrase(
      this.state.phrase,
      this.state.goal,
      (s) => this.setState({'phrase': "Thanks!"}),
      (e) => console.log(e)
    );
  }

  render(){
    return (
      <div className="contentRateContainer">
        <div className="homeMargin" />
          <div className="contentRequest">
          <div className="goalSelectionContainer">
            <h1 className="requestTitle">Rephrase for</h1>
            <select className="requestGoalSelector" onChange={(e)=> this.setState({'goal':e.target.value})}>
              <option value={GOALS.clever}>Clever</option>
              <option value={GOALS.clear}>Clear</option>
              <option value={GOALS.creative}>Creative</option>
              <option value={GOALS.correct}>Correct</option>
            </select>
          </div>
          <div className="requestEntryContainer">
            <div className="textEntryPanel">
              <textarea readOnly={this.state.requestInProgress} className="enterTextArea" placeholder="A phrase to rephrase here, please..." value={this.state.phrase} onChange={(e) => this.setState({'phrase': e.target.value})} />
            </div>
            <BottomButtonPanel changeState={this.props.changeState} actionButton={{'action': ()=>this.requestRephrase(), 'text': "request"}}/>
          </div>
        </div>
        <div className="homeMargin" />
      </div>
    );
  }
}


class ContentHome extends React.Component {

  constructor(props){
    super(props);
  }

  render(){
    return (
      <div className="contentHome">
          <div className="homeMargin" />
          <div className="homeButtonArray">
            <button className="homeButton" onClick={(e)=>this.props.changeState(ACTIVE_CONTENT.rephrase)}>rephrase</button>
            <button className="homeButton" onClick={(e)=>this.props.changeState(ACTIVE_CONTENT.rate)}>rate</button>
            <button className="homeButton" onClick={(e)=>this.props.changeState(ACTIVE_CONTENT.request)}>request</button>
            <button className="homeButton" onClick={(e)=>this.props.changeState(ACTIVE_CONTENT.review)}>review</button>
            <button className="homeButton" onClick={(e)=>this.props.changeState(ACTIVE_CONTENT.reflect)}>reflect</button>
          </div>
          <div className="homeMargin" />
        </div>
    );
  }
}


class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      'activeContent': ACTIVE_CONTENT.home
    }

    this.changeState = this.changeState.bind(this);
  }


  render(){
    return (
      <div className="wholePageContainer">
        <div className="header">
          <h1 className="title" onClick={()=>this.setState({activeContent: ACTIVE_CONTENT.home})}>rephrasor</h1>
        </div>
        <div className="contentContainer">
          {this.state.activeContent === ACTIVE_CONTENT.home && (<ContentHome changeState={this.changeState} />)}
          {this.state.activeContent === ACTIVE_CONTENT.rephrase && (<ContentRephrase changeState={this.changeState} />)}
          {this.state.activeContent === ACTIVE_CONTENT.rate && (<ContentRate changeState={this.changeState} />)}
          {this.state.activeContent === ACTIVE_CONTENT.request && (<ContentRequest changeState={this.changeState} />)}
          {this.state.activeContent === ACTIVE_CONTENT.review && (<ContentReview changeState={this.changeState} />)}
          {this.state.activeContent === ACTIVE_CONTENT.reflect && (<ContentReflect changeState={this.changeState} />)}
        </div>
      </div>
    )
  }

  changeState(newState){
    this.setState({'activeContent': newState});
  }
}


export default App;
